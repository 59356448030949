
// Vue reactivity
import { defineComponent } from 'vue';

// icons
import { arrowBackOutline, close, downloadOutline, logoFacebook, logoWhatsapp, } from 'ionicons/icons';

// components
import { IonHeader, IonToolbar, IonTitle, IonButtons, IonButton, IonIcon,
        IonContent, IonGrid, IonRow, IonCol, IonItem, IonLabel,
        IonCard, IonCardContent, IonCardHeader, IonCardSubtitle,
        modalController, } from '@ionic/vue';

// composables
import { utils } from '@/composables/utils';

export default defineComponent({
  name: 'ShareDesignModal',
  props: ["designDataURL", "designId", "designName"],
  components: { IonHeader, IonToolbar, IonTitle, IonButtons, IonButton, IonIcon,
                IonContent, IonGrid, IonRow, IonCol, IonItem, IonLabel, 
                IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, },
  setup(props) {
    // 1. declare state variables (ref to make them reactive)

    // 2. methods or filters
    const { presentToast, closeModal } = utils();
    
    const shareToWhatsApp = async () => {
      //const sharingMsg = `快來看看我的寵物保鮮花設計！ ${window.location.href}?viewOnly=true`;
      const sharingMsg = `快來看看我的寵物保鮮花設計！ https://d.crystalputeri.com/${props.designId}?viewOnly=true`;
      const url = `https://api.whatsapp.com/send?text=${encodeURIComponent(sharingMsg)}`
      window.open(url, '', 'width=600,height=400');
    }

    const shareToFacebook = async () => {
      //const targetUrl = `${window.location.href}?viewOnly=true`;
      const targetUrl = `https://d.crystalputeri.com/${props.designId}?viewOnly=true`;
      const url = `http://www.facebook.com/sharer/sharer.php?quote=${encodeURIComponent('快來看看我的寵物保鮮花設計！')}&u=${encodeURIComponent(targetUrl)}`
      window.open(url, '', 'width=600,height=400');
    }

    const copyDesignLink = async () => {
      const designLink = `https://d.crystalputeri.com/${props.designId}`;
      navigator.clipboard.writeText(designLink);
      presentToast('已複製連結。')
    }

    // 3. return variables & methods to be used in template HTML
    return {
      // icons
      arrowBackOutline, close, downloadOutline, logoFacebook, logoWhatsapp,

      // variables

      // methods
      closeModal, shareToWhatsApp, shareToFacebook,
      copyDesignLink,
    }
  },
})
