
// Vue reactivity
import { defineComponent } from 'vue';
import { computed, ref } from 'vue';

// icons
import { arrowBack, arrowBackOutline, close, } from 'ionicons/icons';

// components
import { IonHeader, IonToolbar, IonTitle, IonButtons, IonButton, IonIcon,
        IonContent, IonGrid, IonList, IonItem, IonLabel, IonThumbnail, IonBadge,
        modalController, } from '@ionic/vue';
import LoadingSkeleton from "@/components/LoadingSkeleton.vue";

// services
import { useStore } from 'vuex';
import { utils } from '@/composables/utils';
import { useRouter } from 'vue-router';

export default defineComponent({
  name: 'DesignListModal',
  props: ['currDesignId'],
  components: { IonHeader, IonToolbar, IonTitle, IonButtons, IonButton, IonIcon,
                IonContent, IonGrid, IonList, IonItem, IonLabel, IonThumbnail, IonBadge,
                LoadingSkeleton, },
  setup(props) {
    const store = useStore();
    const router = useRouter();
    const { formatDate, closeModal } = utils();

    const loading = computed(() => store.state.loadingUserDesigns);
    const userDesigns = computed(() => store.getters.orderedUserDesigns);

    const openDesign = (designId: any) => {
      closeModal();
      router.replace(`/designs/${designId}`);
    }

    // 3. return variables & methods to be used in template HTML
    return {
      // icons
      arrowBack, arrowBackOutline, close,

      // variables
      loading, userDesigns,

      // methods
      openDesign, closeModal, formatDate
    }
  },
})
