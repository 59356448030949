
// Vue
import { computed, onMounted, reactive, ref, watch } from 'vue';

// icons
import { add, close, arrowForward, chevronBack, chevronUp, chevronDown, layersOutline, createOutline,
        eyeOutline, eyeOffOutline, trashOutline, save, share, shareOutline, expand, contract,
        cardOutline, cartOutline, swapHorizontal, sendOutline, personCircle, call, lockOpenOutline, lockClosedOutline,
        remove, ellipsisHorizontal, ellipsisVertical, pencil, arrowUndoOutline, arrowRedoOutline,
        folderOutline, refresh, copyOutline, helpCircleOutline, } from 'ionicons/icons';

// components
import { IonPage, IonToolbar, IonContent, IonGrid, IonCol, IonRow, IonSpinner,
        IonCard, IonCardHeader, IonCardContent, IonCardTitle, IonCardSubtitle, IonPopover,
        IonSegment, IonSegmentButton, IonLabel, IonAvatar, IonList, IonListHeader, IonItem, IonText,
        IonButtons, IonButton, IonIcon, IonThumbnail, IonReorderGroup, IonReorder, IonChip,
        IonModal, IonHeader, IonTitle, IonAccordion, IonAccordionGroup, IonBackButton, IonInput, IonBadge,
        loadingController, modalController, alertController, onIonViewDidEnter, onIonViewWillLeave, } from '@ionic/vue';
import DesignPreQuestionModal from '@/components/DesignPreQuestionModal.vue';
//import draggable from 'vuedraggable'
import { VueDraggableNext } from 'vue-draggable-next'

// services
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';

import config from '@/config';
import { utils } from '@/composables/utils';
import DesignService from '@/services/DesignService';
import ShareDesignModal from '@/components/ShareDesignModal.vue';
import DesignListModal from '@/components/DesignListModal.vue';
import HelpModal from '@/components/HelpModal.vue';

export default {
  name: 'DesignDetailPage',
  components: { IonPage, IonToolbar, IonContent, IonGrid, IonCol, IonRow, IonSpinner,
                IonCard, IonCardHeader, IonCardContent, IonCardTitle, IonCardSubtitle, IonPopover,
                IonSegment, IonSegmentButton, IonLabel, IonAvatar, IonList, IonListHeader, IonItem, IonText,
                IonButtons, IonButton, IonIcon, IonThumbnail, IonReorderGroup, IonReorder, IonChip, IonBadge,
                IonModal, IonHeader, IonTitle, IonAccordion, IonAccordionGroup, IonBackButton, IonInput,
                draggable: VueDraggableNext },
  setup() {
    console.error = () => false; // for hiding vue-konva error logs

    // methods
    const { t } = useI18n();
    const { getLocalizedStr, arraymove, presentToast, openModal, getDesignItemTotal, uniqueId, presentPrompt, sleep,
            getProxyImgLink, } = utils();
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const { designIdsLocalStorageKey, newDesignLocalStorageKey, } = config;

    // Competition
    const applicant = reactive({
      name: "",
      phone: "",
    })
    const currDesignDataURL = ref("");
    const isSubmitDesignModalOpened = ref(false);

    // DIY design info (canvas)
    const screenWidth = Math.min(window.innerWidth, 350);
    const screenHeight = 342.5;
    const sceneWidth = ref(screenWidth);
    const sceneHeight = ref(screenHeight);

    const canvas = reactive({
      namePlateText: '',
      namePlateTextId: '木名牌字',

      isFlipping: false,
      isResizing: false,
      lockMoveSelectedShape: false, // only allow moving selected shape

      layerVisibility: {
        '寵物': true,
        '主花': true,
        '副花': true,
      },

      croppedBottleBaseImage: null,
      bottleImage: null,
      grassImage: null,
      grassShapeId: null,

      petWidth: 67,
      petHeight: 125,

      bottleWidth: 250,
      bottleHeight: 342.5,
      bottleBaseHeight: 50,

      grassWidth: 180,
      grassHeight: 90,

      shapes: [],
      panelShapes: [], // first item is the top in the canvas

      selectedShape: { id: '' },
    });
    const flowers = computed(() => store.getters.petBottleFlowers);
    const allPets = computed(() => store.state.pets);
    const userLoggedIn = computed(() => store.state.loggedIn);

    // New Design / Editing Existing Designs
    const { showHelpModal } = router.currentRoute.value.query;
    const { id: currDesignId } = route.params; // design ID (for editing existing designs)
    const design = currDesignId ? computed(() => store.getters.getDesignById(currDesignId)) :
                                  computed(() => store.state.newDesign);
    const loadingUserDesigns = computed(() => store.state.loadingUserDesigns);

    // DOM elements
    const stageParentRef = ref(null);
    const stageRef = ref(null);
    const bottleLayerRef = ref(null);
    const flowerLayerRef = ref(null);
    const transformer = ref(null);
    const dummy = ref("dummy"); // for segment buttons (no highlight select)
    const shapePanelRef = ref(null);
    const topElRef = ref(null);
    const vBarShapeBtns = ref([]);

    const scrollToRefElement = (refEl: any, behavior = 'smooth') => {
      refEl.scrollIntoView({
        behavior,
        block: 'center',
        inline: 'center'
      });
    }

    // Page state
    const selectedPanelShape = ref<any>({ colors: [] }); // for showing filtered colors
    const selectedSectionTab = ref("主花");
    const isPriceModalOpened = ref(false);
    const loadingCanvas = ref(true);
    const viewOnly = ref(router.currentRoute.value.query.viewOnly == 'true'); // canvas view only (cannot move / rotate)

    // Popovers
    const isColorPopoverOpenRef = ref(false);
    const colorPopoverEvent = ref();
    const isPopoverOpenRef = ref(false);
    const popoverEvent = ref();
    const setPopoverOpen = (state: boolean, ev: any) => {
      popoverEvent.value = ev; 
      isPopoverOpenRef.value = state;
    }
    
    // undo / redo
    const appHistory = reactive({
      step: -1,
      states: [],
    })
    const saveStateToHistory = (state: any) => {
      appHistory.states = appHistory.states.slice(0, appHistory.step + 1);
      appHistory.states.push(state);
      appHistory.step += 1;
    }

    let loadCanvasTimeout: any = null;
    let serverReqFlag: any = null; // auto save canvas server request

    const IMG_RETRY_MS = 3000; // retry downloading images every X second on failure
    const setCanvasLoaded = (timeout = 3000) => {
      if (loadCanvasTimeout) clearTimeout(loadCanvasTimeout);
      loadingCanvas.value = true; // bug fix prevent empty canvas
      loadCanvasTimeout = setTimeout(() => loadingCanvas.value = false, timeout);
    }
    const loadImg = (imgLink: any, targetVarField: any) => {
      const img = new window.Image();
      img.crossOrigin = 'Anonymous';
      img.src = getProxyImgLink(imgLink);
      img.onload = () => {
        canvas[targetVarField] = img;
        setCanvasLoaded();
      }
      img.onerror = () => { setTimeout(() => img.src = getProxyImgLink(imgLink), IMG_RETRY_MS); }
    }

    const getPetCanvasX = (shape: any) => {
      const { id, width } = shape;
      const { numOfPets } = design.value, offset = 30;
      const centerX = canvas.bottleWidth / 2 - (width || canvas.petWidth) / 2;
      if (numOfPets == 1) return centerX;
      if (numOfPets == 2) return id == 'pet1' ? centerX - offset : centerX + offset;
      if (numOfPets == 3) return id == 'pet1' ? centerX - offset : (id == 'pet2' ? centerX + offset : centerX);
    }
    const getPetCanvasY = (shape: any) => {
      const { id, height } = shape;
      const centerY = canvas.bottleHeight - (height || canvas.petHeight) - (canvas.bottleBaseHeight + canvas.grassHeight / 2);
      if (id == 'pet3') return centerY - 80;
      return centerY;
    }

    /**
     * Transformer functions
     */
    const updateTransformer = () => {
      let selectedNode = null;
      const transformerNode = transformer.value.getNode();

      if (canvas.selectedShape.id) {
        const stage = transformerNode.getStage();
        selectedNode = stage.findOne(`#${canvas.selectedShape.id}`);

        if (selectedNode === transformerNode.node()) return; // do nothing if selected node is already attached
      }
      transformerNode.nodes(selectedNode ? [selectedNode] : []); // attach to another node / remove transformer
    }
    const setCanvasSelectedShape = (shape: any, lockMoveShape = false, scrollToElement = true, scrollBehavior = 'smooth') => {
      if (canvas.selectedShape.id == shape.id && canvas.lockMoveSelectedShape) {
        return; // already is the selected shape)
      }
      canvas.selectedShape = shape;
      canvas.lockMoveSelectedShape = lockMoveShape;
      updateTransformer();
      if (shape.id) {
        const vBarIdx = canvas.panelShapes.findIndex(s => s.id == shape.id);
        if (scrollToElement && vBarIdx != -1) scrollToRefElement(vBarShapeBtns.value[vBarIdx].$el, scrollBehavior);
      }
    }
    const getShapeAttrObj = (stateShape: any) => {
      const grassMinY = canvas.bottleHeight - canvas.bottleBaseHeight - 10;
      if (stateShape.y + stateShape.height >= grassMinY) {
        const croppedHeight = stateShape.heightWithoutStem || 150;
        if (croppedHeight < stateShape.originalHeight) {
          const aspectRatio = croppedHeight / stateShape.originalHeight;
          return {
            height: stateShape.height * aspectRatio,
            cropX: 0,
            cropY: 0,
            cropWidth: stateShape.originalWidth,
            cropHeight: croppedHeight,
          }
        }
      }
      return {
        height: stateShape.height,
        cropX: 0,
        cropY: 0,
        cropWidth: stateShape.originalWidth,
        cropHeight: stateShape.originalHeight,
      }
    }
    
    /**
     * Export canvas data & auto save design canvas
     */
    const getCanvasDataURL = () => {
      if (canvas.selectedShape.id) {
        const transformerNode = transformer.value.getNode();
        transformerNode.nodes([]); // clear transformer selection
      }
      const dataUrl = stageRef.value ? stageRef.value.getStage().toDataURL({
        mimeType: "image/jpeg",
        quality: 0.6,
        pixelRatio: 2.5,
        x: 0,
        width: canvas.bottleWidth,
      }) : "";
      if (canvas.selectedShape.id) updateTransformer();
      return dataUrl;
    }
    const getDBCanvasData = () => {
      const targetKeys = ['petTypeId', 'flowerId', 'type', 'color', 'id', 'width', 'height', 'x', 'y', 'rotation', 'scaleX', 'scaleY'];
      return JSON.stringify({
        namePlateText: canvas.namePlateText,
        grassShapeId: canvas.grassShapeId,
        shapes: canvas.shapes.map((s: any) => {
          const resObj = {};
          for (const key of targetKeys) {
            if (s[key]) {
              resObj[key] = ['x','y','scaleX','scaleY','rotation'].includes(key) ? (Math.round(s[key] * 100) / 100) : s[key];
            }
          }
          return resObj;
        })
      });
    }
    const autoSaveDesignCanvas = (timeout = 2000) => {
      if (allPets.value.length == 0 || canvas.shapes.length == 0) return; // not save canvas if pets not fully loaded

      if (serverReqFlag) clearTimeout(serverReqFlag);

      serverReqFlag = setTimeout(() => {
        const canvasData = getDBCanvasData();

        store.commit('updateDesign', { designId: design.value.id, updatedObj: { canvas, canvasJson: canvasData } });

        if (design.value.id) { // editing existing design: save to DB directly
          DesignService.upsertDesign({
            designId: design.value.id,
            name: design.value.name,
            canvasData,
            canvasDataURL: getCanvasDataURL(),
          });
        } else {
          localStorage.setItem(newDesignLocalStorageKey, JSON.stringify({
            ...design.value,
            canvas,
            canvasJson: canvasData
          }));
        }
      }, timeout);
    }
    const updateShapeAttrs = (action: any = '', shapeId: any, attrs: any = {}) => {
      const shapeIdx = canvas.shapes.findIndex((s) => s.id == shapeId);
      const prevShape = { ...canvas.shapes[shapeIdx] };
      for (const key in attrs) {
        canvas.shapes[shapeIdx][key] = attrs[key];
      }
      saveStateToHistory({
        action,
        prevShape,
        nextShape: { ...canvas.shapes[shapeIdx] }
      });
      autoSaveDesignCanvas(3000);
    }

    /**
     * 木名牌 text input
     */
    const presentNamePlateTextInput = async () => {
      const alert = await alertController.create({
        header: '木名牌文字',
        inputs: [
          {
            name: 'namePlateText',
            type: 'textarea',
            placeholder: '建議不超過10個中文字或15個英文字母',
            value: canvas.namePlateText || "",
          },
        ],
        buttons: [
          {
            text: 'Cancel',
            role: 'cancel',
            cssClass: 'secondary',
          }, {
            text: '確定',
            handler: (value) => {
              canvas.namePlateText = value.namePlateText;
              autoSaveDesignCanvas(10);
            }
          }
        ]
      });
      await alert.present();
    }

    /**
     * addShape: Add shape to specific index
     * removeShape: Remove shape of ID from canvas
     * Recover: shape attrs (x, y, rotation, scale)
     * updateShapeLayerOrder: Move shape to specific order in the layer
     */
    const addShape = (shape: any, shapeIdx: any, panelShapeIdx: any, setShapeSelected = true) => {
      canvas.panelShapes.splice(panelShapeIdx, 0, shape);
      canvas.shapes.splice(shapeIdx, 0, shape);
      if (setShapeSelected) {
        if (vBarShapeBtns.value[panelShapeIdx]) scrollToRefElement(vBarShapeBtns.value[panelShapeIdx].$el);
        setTimeout(() => setCanvasSelectedShape(shape), 100);
      }
    }

    const removeShape = (shapeId: any, saveHistory = false) => {
      const panelShapeIdx = canvas.panelShapes.findIndex(s => s.id == shapeId);
      const shapeIdx = canvas.shapes.findIndex(s => s.id == shapeId);
      canvas.panelShapes.splice(panelShapeIdx, 1); // panel shape (first element is on the top of layer)
      const removedShapes = canvas.shapes.splice(shapeIdx, 1); // canvas shape (last element is on the top of layer)
      if (saveHistory) {
        saveStateToHistory({
          action: 'removeShape',
          shape: { ...removedShapes[0] },
          panelShapeIdx,
          shapeIdx,
        });
        autoSaveDesignCanvas();
      }
      if (shapeId == canvas.selectedShape.id) setCanvasSelectedShape({ id: '' });
    }
    const recoverShape = (shape: any) => {
      const relatedShapeIdx = canvas.shapes.findIndex((s) => s.id == shape.id);
      canvas.shapes[relatedShapeIdx] = { ...shape };
      if (shape.id == canvas.selectedShape.id) setCanvasSelectedShape(canvas.shapes[relatedShapeIdx]);
    }
    const updateShapeLayerOrder = (oldShapeArrIdx: any, newShapeArrIdx: any, oldPanelShapeArrIdx: any, newPanelShapeArrIdx: any) => {
      arraymove(canvas.shapes, oldShapeArrIdx, newShapeArrIdx);
      arraymove(canvas.panelShapes, oldPanelShapeArrIdx, newPanelShapeArrIdx);
      if (vBarShapeBtns.value[newPanelShapeArrIdx]) {
        scrollToRefElement(vBarShapeBtns.value[newPanelShapeArrIdx].$el); // scroll to the element
      }
    }
    
    /**
     * Resize / transform / select shapes
     */
    const boundBoxFunc = (oldBoundBox: any, newBoundBox: any) => {
      if (viewOnly.value) { // canvas view only
        return oldBoundBox;
      }
      const rad = Math.abs(newBoundBox.rotation);
      if (rad > 1.05 && rad < 5.23) { // not allow rotating to area between 60 and 300 degrees
        return oldBoundBox;
      }
      return (canvas.selectedShape['type'] == '寵物') ? oldBoundBox : newBoundBox; // not allow to resize pets
      //const newWidth = Math.abs(newBoundBox.width);
      //const MIN_WIDTH = 50, MAX_WIDTH = 250;
      //return (newWidth < MIN_WIDTH || newWidth > MAX_WIDTH) ? oldBoundBox : newBoundBox;
    }

    /**
     * Add flower to Canvas
     */
    const addFlowerToCanvas = (flower: any, color: any, ev: any) => {
      if (flower.type == '草皮') { // 1 bottle 1 草皮 only
        canvas.grassShapeId = flower.id;
        canvas.grassImage = flower.loadedImg; // update the grass image on canvas
        canvas.grassWidth = flower.width || canvas.grassWidth;
        canvas.grassHeight = flower.height || canvas.grassHeight;
      } else {
        if (flower.name == '木名牌') {
          const existingShape = canvas.shapes.find((s) => s.name == flower.name);
          if (existingShape) {
            setCanvasSelectedShape(existingShape);
            return;
          }
        }
        if (flower.colors.length == 0 || color) {
          const canvasFlower = {
            ...flower,
            ...color,
            flowerId: flower.id,
            id: Math.random().toString(36).slice(-8),
            visible: true,
          };
          addShape(canvasFlower, canvas.shapes.length, 0);
          
          saveStateToHistory({
            action: 'addShape',
            shape: { ...canvasFlower },
          });
          
          if (flower.name == '木名牌') {
            presentNamePlateTextInput(); // input name plate text
          }
        } else {
          scrollToRefElement(ev.target, 'auto');
          selectedPanelShape.value = flower;
          isColorPopoverOpenRef.value = true;
          colorPopoverEvent.value = ev;

        }
      }
      autoSaveDesignCanvas();
    }

    /**
     * Canvas / flower event listeners
     */
    const handleStageMouseDown = (e: any) => {
      //if (canvas.lockMoveSelectedShape) return;

      // clicked on stage - clear selection
      try {
        if (canvas.lockMoveSelectedShape) {
          if (!e.target.getLayer().name()) setCanvasSelectedShape({ id: '' }); // release lock if click empty area
          return;
        }
        if (e.target === e.target.getStage() || e.target.getLayer().name() != 'flowerLayer') {
          setCanvasSelectedShape({ id: '' });
          return;
        }
      } catch (e) { return; }

      const clickedOnTransformer = (e.target.getParent().className === 'Transformer');
      if (clickedOnTransformer) return;

      // find clicked rect by its name
      const id = e.target.getId();
      const shape = (id == canvas.namePlateTextId) ? canvas.shapes.find((s) => s.name == '木名牌')
                                                    : canvas.shapes.find((s) => s.id == id);
      setCanvasSelectedShape(shape || { id: '' }, false, true, 'auto');
    }
    const handleTransformEnd = (e: any) => {
      updateShapeAttrs('transform', canvas.selectedShape.id, {
        x: e.target.x(),
        y: e.target.y(),
        rotation: e.target.rotation(),
        scaleX: e.target.scaleX(),
        scaleY: e.target.scaleY(),
      });
    }
    const handleMouseEnter = (e: any) => {
      stageRef.value.getStage().container().style.cursor = 'move';
    };
    const handleMouseLeave = (e: any) => {
      stageRef.value.getStage().container().style.cursor = 'default';
    };
    const handleTrDragEnd = (e: any) => {
      function getTotalBox(boxes) {
        let minX = Infinity, minY = Infinity, maxX = -Infinity, maxY = -Infinity;
        boxes.forEach((box: any) => {
          minX = Math.min(minX, box.x);
          minY = Math.min(minY, box.y);
          maxX = Math.max(maxX, box.x + box.width);
          maxY = Math.max(maxY, box.y + box.height);
        });
        return { x: minX, y: minY, width: maxX - minX, height: maxY - minY, };
      }
      const stage = stageRef.value.getStage();
      const tr = transformer.value.getNode();
      const boxes = tr.nodes().map((node: any) => node.getClientRect());
      const box = getTotalBox(boxes);
      tr.nodes().forEach((shape: any) => {
        const absPos = shape.getAbsolutePosition();
        // where are shapes inside bounding box of all shapes?
        const offsetX = box.x - absPos.x;
        const offsetY = box.y - absPos.y;

        // we total box goes outside of viewport, we need to move absolute position of shape
        const newAbsPos = { ...absPos };
        
        if (box.x < 0) newAbsPos.x = -offsetX;
        if (box.y < 0) newAbsPos.y = -offsetY;

        if (box.x + box.width > stage.width()) {
          newAbsPos.x = stage.width() - box.width - offsetX;
        }
        if (box.y + box.height > stage.height()) {
          newAbsPos.y = stage.height() - box.height - offsetY;
        }
        shape.setAbsolutePosition(newAbsPos);

        updateShapeAttrs('move', shape.id(), {
          x: newAbsPos.x,
          y: newAbsPos.y,
        });
      });
    };

    /**
     * Shape actions (e.g. flower Reordering / set selected shapes)
     */
    const updateShapeSize = (size: any) => {
      canvas.isResizing = true;
      const RESIZE_RATIO = 1.3;
      const targetNode = stageRef.value.getStage().findOne(`#${canvas.selectedShape.id}`);

      const newShapeAttrs = (size == 'large' ? {
        size: 'large',
        scaleX: targetNode.scaleX() * RESIZE_RATIO,
        scaleY: targetNode.scaleY() * RESIZE_RATIO,
      } : {
        size: 'normal',
        scaleX: targetNode.scaleX() / RESIZE_RATIO,
        scaleY: targetNode.scaleY() / RESIZE_RATIO,
      });

      // resize the shapes
      targetNode.to({
        scaleX: newShapeAttrs.scaleX,
        scaleY: newShapeAttrs.scaleY,
        onFinish: () => canvas.isResizing = false,
      });
      updateShapeAttrs('resize', canvas.selectedShape.id, newShapeAttrs);
    }
    const flipSelectedShape = () => {
      canvas.isFlipping = true;
      const targetNode = stageRef.value.getStage().findOne(`#${canvas.selectedShape.id}`);
      targetNode.to({
        scaleX: -targetNode.scaleX(), // flip the shape on canvas
        onFinish: () => canvas.isFlipping = false,
      });
      updateShapeAttrs('flip', canvas.selectedShape.id, { scaleX: -targetNode.scaleX() });
    }
    const moveSelectedShape = (direction: any) => { // update order of selected shape in the layer
      if (canvas.selectedShape.id) {
        const currShapeArrIdx = canvas.shapes.findIndex(s => s.id == canvas.selectedShape.id);
        const currPanelShapeArrIdx = canvas.panelShapes.findIndex(s => s.id == canvas.selectedShape.id);
        const lastArrIdx = canvas.shapes.length-1;
        let newShapeArrIdx, newPanelShapeArrIdx;
        if (direction == 'top') {
          newShapeArrIdx = lastArrIdx;
          newPanelShapeArrIdx = 0;
        }
        else if (direction == 'bottom') {
          newShapeArrIdx = 0;
          newPanelShapeArrIdx = lastArrIdx;
        }
        else if (direction == 'up') {
          newShapeArrIdx = Math.min(currShapeArrIdx+1, lastArrIdx);
          newPanelShapeArrIdx = Math.max(currPanelShapeArrIdx-1, 0);
        }
        else if (direction == 'down') {
          newShapeArrIdx = Math.max(currShapeArrIdx-1, 0);
          newPanelShapeArrIdx = Math.min(currPanelShapeArrIdx+1, lastArrIdx);
        }
        updateShapeLayerOrder(currShapeArrIdx, newShapeArrIdx, currPanelShapeArrIdx, newPanelShapeArrIdx);

        saveStateToHistory({
          action: 'updateShapeLayerOrder',
          shapeId: canvas.selectedShape.id,
          currShapeArrIdx,
          currPanelShapeArrIdx,
          newShapeArrIdx,
          newPanelShapeArrIdx,
        });
      }
    }
    const onChooseElement = (event: any) => {
      setCanvasSelectedShape(canvas.panelShapes[event.oldIndex]);
    }
    const doShapeReorder = (event: any) => {
      canvas.shapes = canvas.panelShapes.slice().reverse();
    };
    const updateSelectedShape = (direction: any) => {
      let nextIdx = 0;
      const idx = canvas.panelShapes.findIndex(s => s.id == canvas.selectedShape.id);
      if (idx !== -1) {
        if (direction == 'next') nextIdx = idx+1 > canvas.panelShapes.length-1 ? idx : idx+1;
        else nextIdx = idx-1 < 0 ? 0 : idx-1;
      }
      setCanvasSelectedShape(canvas.panelShapes[nextIdx]);
      if (vBarShapeBtns.value[nextIdx]) scrollToRefElement(vBarShapeBtns.value[nextIdx].$el); // scroll to the element
    };
    const selectNextPanelShape = (currShape: any) => {
      const idx = canvas.panelShapes.findIndex(s => s.id == currShape.id);
      if (idx !== -1) {
        const nextIdx = idx+1 > canvas.panelShapes.length-1 ? 0 : idx+1;
        setCanvasSelectedShape(canvas.panelShapes[nextIdx]);
      }
    }
    const getOrderedCanvasShapes = () => {
      //const selectedShapeId = canvas.selectedShape.id;
      //return selectedShapeId ? [...canvas.shapes.filter(s => s.id != selectedShapeId), canvas.selectedShape] : canvas.shapes;
      return canvas.shapes.filter(s => !!s.id);
    }
    const getSelectedShapeName = (selectedShape: any) => {
      const idx = canvas.panelShapes.findIndex(s => s.id == selectedShape.id);
      return (idx !== -1 ? `${idx+1}.${selectedShape.name}` : '沒有選擇');
    }

    /**
     * Show / hide elements / layers
     */
    const setShapeVisible = (shape: any, visible: any) => {
      updateShapeAttrs('setShapeVisible', shape.id, { visible });
    }
    const toggleLayerVisibility = (layerName: any) => {
      const newVisible = !canvas.layerVisibility[layerName]; // new visibility
      canvas.layerVisibility[layerName] = newVisible;
      if (canvas.selectedShape['type'] == layerName) setCanvasSelectedShape({ id: '' });
    }
    const clearCanvasShapes = () => {
      presentPrompt('確定清除所有已選擇的花？', async () => {
        const loading = await loadingController.create({});
        await loading.present();
        sleep(1);
        canvas.panelShapes = canvas.panelShapes.filter(s => s.type == '寵物');
        canvas.shapes = canvas.shapes.filter(s => s.type == '寵物');
        canvas.selectedShape = { id: '' };
        updateTransformer(); // refresh transformer
        autoSaveDesignCanvas();
        loading.dismiss();
      });
    }

    /**
     * Responsive stage
     */
    const fitStageIntoParentContainer = () => {
      if (stageRef.value) {
        const stage = stageRef.value.getStage();
        const containerWidth = stageParentRef.value.offsetWidth;
        if (containerWidth > 0) {
          const scale = containerWidth / screenWidth;
          //const newStageHeight = Math.max(screenHeight * scale, MIN_SCREEN_HEIGHT);

          stage.width(screenWidth * scale);
          //stage.height(newStageHeight);
          //stage.scale({ x: scale, y: scale });

          sceneWidth.value = screenWidth * scale;
          //sceneHeight.value = newStageHeight;
        }
      }
    }

    /**
     * Cart / order item (price-related functions)
     */
    const addDesignToCart = async (design: any) => {
      const loading = await loadingController.create({});
      await loading.present();
      store.dispatch('addDesignToCart', { design });
      loading.dismiss();
      presentToast( t('successAddedToCart'), 2000, 'top' );
    }
    const getOrderItemTotal = () => getDesignItemTotal(design.value, canvas.shapes);

    const getGroupedCanvasShapes = () => {
      const { bottle, numOfPets } = design.value;
      let bottlePetType = bottle['簡稱'];
      if (numOfPets > 0) bottlePetType += `${numOfPets}隻寵物`;
      
      const groupedShapeTypes: any = {
        [bottlePetType]: {
          totalPrice: Number(numOfPets == 0 ? bottle['純樽售價'] : bottle[`${numOfPets}隻寵物售價`]),
          groupedShapes: {
            [bottle.id]: { shapes: [bottle] }
          },
        }
      };
      for (const shape of canvas.shapes) {
        const { id, flowerId, type, sellingPrice, size, priceBigSize } = shape;
        const key = `${flowerId || id}${size}`;
        if (type == '寵物') {
          groupedShapeTypes[bottlePetType].groupedShapes[key] = groupedShapeTypes[bottlePetType].groupedShapes[key] || { shapes: [] };
          groupedShapeTypes[bottlePetType].groupedShapes[key].shapes.push(shape);
        } else {
          const price = (size == 'large' ? priceBigSize || sellingPrice : sellingPrice);
          groupedShapeTypes[type] = groupedShapeTypes[type] || { totalPrice: 0, groupedShapes: {} };
          groupedShapeTypes[type].groupedShapes[key] = groupedShapeTypes[type].groupedShapes[key] || { totalPrice: 0, shapes: [] };
          groupedShapeTypes[type].groupedShapes[key].totalPrice += Number(price || 0);
          groupedShapeTypes[type].groupedShapes[key].shapes.push(shape);
          groupedShapeTypes[type].totalPrice += Number(price || 0);
        }
      }
      return groupedShapeTypes;
    }

    /**
     * Share canvas / Open modals
     */
    const shareCanvas = async () => {
      setCanvasSelectedShape({ id: '' }); // clear selection first
      const dataURL = getCanvasDataURL();
      await openModal(ShareDesignModal, { designDataURL: dataURL, designId: design.value.id, designName: design.value.name });
    }
    const openDesignListModal = async () => { await openModal(DesignListModal, { currDesignId }); }
    const openHelpModal = async (useSheetModal: any) => {
      const extraOps = useSheetModal ? {
        initialBreakpoint: 0.5,
        breakpoints: [0, 0.5, 1]
      } : {};
      const modal = await modalController.create({
        component: HelpModal,
        ...extraOps
      })
      return modal.present();
    }

    /**
     * Save / load canvas shapes
     */
    const saveNewDesign = async (design: any, designName: any, canvasData: any, canvasDataURL: any) => {
      const designId = uniqueId();

      const { pet1, pet2, pet3, bottle, withPets, } = design;
      let { pet1Id, pet2Id, pet3Id, } = design;
      const numOfPets = [pet1, pet2, pet3].filter(p => !!p).length;

      pet1Id = pet1Id || uniqueId();
      pet2Id = pet2Id || uniqueId();
      pet3Id = pet3Id || uniqueId();

      // update design (pet1, pet2, pet3)
      const res = await DesignService.upsertDesign({
        newDesignId: designId,
        name: designName,
        canvasData,
        canvasDataURL,
        extraData: {
          'with_pets': withPets,
          'bottle_id': bottle.id,
          'num_of_pets': numOfPets,
          'pet1_id': pet1Id,
          'pet2_id': pet2Id,
          'pet3_id': pet3Id,
        }
      });
      
      // add design pets
      DesignService.upsertDesignPets([
        { id: pet1Id, designId, petTypeId: pet1 ? pet1.petTypeId : "" },
        { id: pet2Id, designId, petTypeId: pet2 ? pet2.petTypeId : "" },
        { id: pet3Id, designId, petTypeId: pet3 ? pet3.petTypeId : "" },
      ]);

      const latestNewDesign = {
        ...design,
        id: res.id,
        name: designName,
        canvasJson: canvasData,
        canvas: { ...canvas },
      };
      store.commit('receiveNewDesign', latestNewDesign);
      localStorage.setItem(newDesignLocalStorageKey, JSON.stringify(latestNewDesign)); // with the ID so that can continue design

      const newDesign = { ...res, ...latestNewDesign };
      store.commit('upsertUserDesign', newDesign);
      
      const currDesignIds = JSON.parse(localStorage.getItem(designIdsLocalStorageKey) || '[]');
      localStorage.setItem(designIdsLocalStorageKey, JSON.stringify([res.id, ...currDesignIds]));

      window.history.replaceState({}, document.title, `/designs/${res.id}`);
      //router.replace(`/designs/${res.id}`); // update the existing route (/new-design -> /designs/:id)
    }
    const presentDesignNameInput = async (callback: any, prefilledDesignName = "") => {
      const alert = await alertController.create({
        header: '請輸入設計名稱',
        inputs: [
          {
            name: 'designName',
            type: 'text',
            placeholder: '設計名稱',
            value: prefilledDesignName || design.value.name || "",
          },
        ],
        buttons: [
          {
            text: 'Cancel',
            role: 'cancel',
            cssClass: 'secondary',
          }, {
            text: prefilledDesignName ? '建立副本' : '儲存',
            handler: (value) => {
              if (value.designName) {
                callback(value.designName);
              }
              return false;
            }
          }
        ]
      });
      await alert.present();
    }
    const cloneDesign = async (design: any) => {
      await presentDesignNameInput(async (designName: any) => {
        const loading = await loadingController.create({});
        await loading.present();
        try {
          await saveNewDesign(design, designName, getDBCanvasData(), getCanvasDataURL());
        } catch (e) {
          presentToast("複製設計失敗，請重試。");
        } finally {
          alertController.dismiss();
          loading.dismiss();
        }
      }, `${design.name} 的副本`);
    }
    const saveCanvas = async (design: any) => {
      const canvasData = getDBCanvasData();

      // save to DB as well
      await presentDesignNameInput(async (designName: any) => {
        const loading = await loadingController.create({});
        await loading.present();
        try {
          design.name = designName;
          const canvasDataURL = getCanvasDataURL();
          if (design.id) { // editing existing design
            DesignService.upsertDesign({
              designId: design.id,
              name: designName,
              canvasData,
              canvasDataURL,
            });
            store.commit('updateDesign', { designId: design.id, updatedObj: { name: designName, canvas, canvasJson: canvasData } });
          }
          else { // create new design
            await saveNewDesign(design, designName, canvasData, canvasDataURL);
          }
          presentToast("已成功儲存。");
        } catch (e) {
          presentToast("儲存失敗，請重試。");
        } finally {
          alertController.dismiss();
          loading.dismiss();
        }
      });
    }

    const loadSavedCanvas = (design: any = {}) => {
      const { canvas: data } = design;
      const { namePlateText, grassShapeId, shapes } = data;
      if (namePlateText) {
        canvas.namePlateText = namePlateText;
      }
      if (grassShapeId) {
        const relatedShape = flowers.value.find((f: any) => f.id == grassShapeId);
        loadImg(relatedShape.photoLink, 'grassImage');
        canvas.grassWidth = relatedShape.width || canvas.grassWidth;
        canvas.grassHeight = relatedShape.height || canvas.grassHeight;
      }
      if (shapes) {
        let currShapeCount = canvas.shapes.length;
        for (const shape of shapes) {
          if (shape.type == '寵物') {
            //const pet = allPets.value.find((p: any) => p.id == shape.petTypeId);
            const pet = allPets.value.find((p: any) => p.id == design.pet1.petTypeId); // TODO: handle more than 1 pets
            if (pet) {
              //const canvasPet = { ...shape, ...pet };
              const canvasPet = { ...design.pet1, ...pet, type: '寵物' };
              canvas.shapes[currShapeCount++] = canvasPet;
              const img = new window.Image();
              img.crossOrigin = 'Anonymous';
              img.src = getProxyImgLink(canvasPet.photoLink);
              img.onload = () => {
                canvasPet.loadedImg = img;
                setCanvasLoaded();
              }
              img.onerror = () => { setTimeout(() => img.src = getProxyImgLink(canvasPet.photoLink), IMG_RETRY_MS); }
              canvas.panelShapes.unshift(canvasPet);
            }
          }
          else {
            const flower = flowers.value.find((f: any) => f.id == shape.flowerId);
            if (flower) {
              let color = {};
              if (shape.color) {
                color = flower.colors.find((c: any) => c.color == shape.color) || {};
              }
              const canvasFlower = {
                ...shape,
                ...flower,
                ...color,
                flowerId: flower.id,
                id: Math.random().toString(36).slice(-8),
                visible: true,
                //loadedImg: null,
              };
              canvas.shapes[currShapeCount++] = canvasFlower;
              canvas.panelShapes.unshift(canvasFlower);
              /*
              const img = new window.Image();
              img.crossOrigin = 'Anonymous';
              img.src = getProxyImgLink(canvasFlower.photoLink);
              img.onload = () => {
                canvasFlower.loadedImg = img;
                setCanvasLoaded();
              }
              img.onerror = () => { setTimeout(() => img.src = getProxyImgLink(canvasFlower.photoLink), IMG_RETRY_MS); }
              */
            }
          }
        }
        autoSaveDesignCanvas(10);
      }
    }

    /**
     * UNDO / REDO (app history)
     */
    const undo = () => {
      if (appHistory.step === -1) return;

      const currState = appHistory.states[appHistory.step--];

      if (currState.action == 'updateShapeLayerOrder') {
        const { currShapeArrIdx, newShapeArrIdx, currPanelShapeArrIdx, newPanelShapeArrIdx } = currState;
        updateShapeLayerOrder(newShapeArrIdx, currShapeArrIdx, newPanelShapeArrIdx, currPanelShapeArrIdx);
      }
      else if (currState.action == 'addShape') {
        removeShape(currState.shape.id);
      }
      else if (currState.action == 'removeShape') {
        const { shape, panelShapeIdx, shapeIdx } = currState;
        addShape(shape, shapeIdx, panelShapeIdx);
      }
      else {
        recoverShape(currState.prevShape);
      }
    }
    const redo = () => {
      if (appHistory.step === appHistory.states.length-1) return;

      const currState = appHistory.states[++appHistory.step];

      if (currState.action == 'updateShapeLayerOrder') {
        const { currShapeArrIdx, newShapeArrIdx, currPanelShapeArrIdx, newPanelShapeArrIdx } = currState;
        updateShapeLayerOrder(currShapeArrIdx, newShapeArrIdx, currPanelShapeArrIdx, newPanelShapeArrIdx);
      }
      else if (currState.action == 'addShape') {
        addShape(currState.shape, canvas.shapes.length, 0);
      }
      else if (currState.action == 'removeShape') {
        removeShape(currState.shape.id);
      }
      else {
        recoverShape(currState.nextShape);
      }
    }

    // add pet shape to canvas
    const addPetShapeToCanvas = (shapeObj: any) => {
      addShape(shapeObj, 0, canvas.panelShapes.length, false);
      //if (shapeObj.id == 'pet3') addShape(shapeObj, 0, canvas.panelShapes.length, false);
      //else addShape(shapeObj, canvas.shapes.length, 0, false);
    }

    const loadCanvasBottle = (bottle: any) => {
      if (bottle && bottle.id) {
        loadImg(bottle.basePhotoLink, 'croppedBottleBaseImage');
        loadImg(bottle.glassPhotoLink, 'bottleImage');
        canvas.bottleWidth = Number(bottle.canvasWidth);
        canvas.bottleHeight = Number(bottle.canvasHeight);
        canvas.bottleBaseHeight = Number(bottle.canvasBaseHeight);
      }
      /*else {
        openDesignPreQuestionModal();
      }*/
    }

    /**
     * Design pre questions (pet types & bottles)
     */
    const syncCanvasSettings = () => {
      const { bottle, withPets, numOfPets, pet1, pet2, pet3 } = design.value;

      loadCanvasBottle(bottle);

      // remove existing pets first
      const petShapeIds = canvas.panelShapes.filter(s => s.type == '寵物').map(s => s.id);
      for (const id of petShapeIds) removeShape(id);
      
      if (withPets == true) {
        const pets = [{ id: 'pet1', obj: pet1 }, { id: 'pet2', obj: pet2 }, { id: 'pet3', obj: pet3 }];

        // add latest pets
        for (const pet of pets) {
          if (pet.obj && pet.obj.petTypeId && pet.obj.photoLink) {
            const dbPet = allPets.value.find((p: any) => p.id == pet.obj.petTypeId);
            const petObj = { id: pet.id, type: '寵物', name: pet.obj.petTypeName, visible: true, ...pet.obj, ...dbPet, };
            addPetShapeToCanvas(petObj);
            const img = new window.Image();
            img.crossOrigin = 'Anonymous';
            img.src = getProxyImgLink(petObj.photoLink);
            img.onload = () => {
              petObj.loadedImg = img;
              setCanvasLoaded();
            }
            img.onerror = () => { setTimeout(() => img.src = getProxyImgLink(petObj.photoLink), IMG_RETRY_MS); }
          }
        }
      }
      autoSaveDesignCanvas(10);
    }
    const openDesignPreQuestionModal = async () => {
      const modal = await modalController.create({
        component: DesignPreQuestionModal,
        componentProps: { designId: currDesignId },
      });
      modal.onDidDismiss().then(() => {
        syncCanvasSettings();
      });
      return modal.present();
    };

    const goToHomePageWithDesignModal = () => {
      router.push({ name: 'HomePage', params: { openDesignModal: "1" } }); // go to product page and pre-select category
    }

    /**
     * Init Canvas (pet & bottle base)
     */
    const initCanvas = () => {
      // set up the bottle (base photo)
      const { bottle, withPets, numOfPets, pet1, pet2, pet3, canvasJson } = design.value;
      
      loadCanvasBottle(bottle);
      
      // set up the grass image (default green)
      loadImg("https://cms.signals.hk/crystalputeri/grass.png", 'grassImage');

      // set up pets (1 / 2 / 3 pets)
      if (withPets == true && !canvasJson) {
        const pets = [{ id: 'pet1', obj: pet1 }, { id: 'pet2', obj: pet2 }, { id: 'pet3', obj: pet3 }];
        for (const pet of pets) {
          if (pet.obj && pet.obj.petTypeId && pet.obj.photoLink) {
            const dbPet = allPets.value.find((p: any) => p.id == pet.obj.petTypeId);
            const petObj = { name: pet.obj.petTypeName, ...pet.obj, ...dbPet, id: pet.id, type: '寵物', visible: true };
            const img = new window.Image();
            img.crossOrigin = 'Anonymous';
            img.src = getProxyImgLink(petObj.photoLink);
            img.onload = () => {
              addPetShapeToCanvas({ ...petObj, loadedImg: img, });
              setCanvasLoaded();
            }
            img.onerror = () => { setTimeout(() => img.src = getProxyImgLink(petObj.photoLink), IMG_RETRY_MS); }
          }
        }
      }
    }

    /**
     * Handle flowers retrieved from DB
     */
    const loadFlowerImages = (flowers: any) => {
      loadingCanvas.value = true;
      const promises = [];
      for (const f of flowers) {
        if (!('loadedImg' in f)) {
          promises.push(
            new Promise((resolve, reject) => {
              f.loadedImg = null;
              const img = new window.Image();
              img.crossOrigin = 'Anonymous';
              img.src = getProxyImgLink(f.photoLink);
              img.onload = () => {
                f.loadedImg = img;
                resolve(true);
              }
              img.onerror = () => { setTimeout(() => img.src = getProxyImgLink(f.photoLink), IMG_RETRY_MS); }
            })
          )
        }
        // check & load the related colors as well
        if (f.colors && f.colors.length > 0) {
          for (const c of f.colors) {
            if (!('loadedImg' in c)) {
              promises.push(
                new Promise((resolve, reject) => {
                  c.loadedImg = null;
                  const img = new window.Image();
                  img.crossOrigin = 'Anonymous';
                  img.src = getProxyImgLink(c.photoLink);
                  img.onload = () => {
                    c.loadedImg = img;
                    resolve(true);
                  }
                  img.onerror = () => { setTimeout(() => img.src = getProxyImgLink(c.photoLink), IMG_RETRY_MS); }
                })
              );
            }
          }
        }
      }
      Promise.all(promises).then(res => {
        if (design.value.canvasJson) loadSavedCanvas(design.value);
        else {
          autoSaveDesignCanvas();
          setCanvasLoaded(100); // will be dismissed after loading canvas
        }
      });
    }

    const leaveDesign = async () => {
      await presentPrompt("確認離開設計並返回主頁？", () => {
        router.replace("/home");
      });
    }

    onIonViewDidEnter(() => {
      if (loadingCanvas.value == true) {
        loadFlowerImages(flowers.value);
        initCanvas();
        //if (design.value.canvasJson) loadSavedCanvas(design.value);
        //else autoSaveDesignCanvas();

        fitStageIntoParentContainer();
        window.addEventListener('resize', fitStageIntoParentContainer);
      }
    });
    watch(flowers, (currFlowers: any) => { // triggered only when direct access to this page
      loadFlowerImages(currFlowers);
      //if (design.value.canvasJson) loadSavedCanvas(design.value);
    })
    watch(design, (currDesign: any) => { // triggered only when direct access to this page
      if (currDesignId && !currDesign.id) {
        // retrieve the design directly from DB
        store.commit('setLoadingUserDesigns', true);
        DesignService.getDesignsByIds([currDesignId]).then(res => {
          store.commit('addNewUserDesigns', res);
          loadingCanvas.value = true;
        });
      } else {
        canvas.shapes = []; // reset
        canvas.panelShapes = []; // reset
        initCanvas();
        if (currDesign.canvasJson) loadSavedCanvas(currDesign);
      }
    });
    //setTimeout(() => loadingCanvas.value = false, 2000);

    const openSubmitDesignModal = () => {
      currDesignDataURL.value = getCanvasDataURL();
      isSubmitDesignModalOpened.value = true;
    }

    const submitDesign = async () => {
      const loading = await loadingController.create({});
      await loading.present();

      const canvasJson = getDBCanvasData();
      const res = await DesignService.insertDesignSubmission({
        applicant,
        design: design.value,
        canvasJson,
        canvasDataURL: currDesignDataURL.value,
      });
      presentToast("已成功提交設計！", 5000);
      isSubmitDesignModalOpened.value = false;

      loading.dismiss();
    }

    if (!currDesignId) {
      if (design.value.withPets == null) {
        goToHomePageWithDesignModal();
      }
      saveNewDesign(design.value, "Untitled", getDBCanvasData(), getCanvasDataURL());
    }

    if (showHelpModal == "1") {
      setTimeout(() => openHelpModal(true), 500);
    }

/* This will disable scrolling as well..
    document.addEventListener('touchmove', (event: any) => {
      if (event.scale !== 1) { event.preventDefault(); }
    }, { passive: false });
*/
    return {
      // icons
      add, close, arrowForward, chevronBack, chevronUp, chevronDown, layersOutline, createOutline,
      eyeOutline, eyeOffOutline, trashOutline, save, share, shareOutline, expand, contract,
      cardOutline, cartOutline, swapHorizontal, sendOutline, personCircle, call, lockOpenOutline, lockClosedOutline,
      remove, ellipsisHorizontal, ellipsisVertical, pencil, arrowUndoOutline, arrowRedoOutline,
      folderOutline, refresh, copyOutline, helpCircleOutline,

      // variables
      loadingUserDesigns, loadingCanvas, canvas, design, viewOnly,
      sceneWidth, sceneHeight,
      stageParentRef, stageRef,
      bottleLayerRef, flowerLayerRef,
      shapePanelRef, topElRef,
      transformer, dummy,
      flowers,
      isPriceModalOpened,
      selectedSectionTab, selectedPanelShape, getSelectedShapeName,
      applicant, currDesignDataURL, isSubmitDesignModalOpened,
      isPopoverOpenRef, popoverEvent,
      isColorPopoverOpenRef, colorPopoverEvent,
      vBarShapeBtns,

      // methods
      t, getLocalizedStr,
      getDBCanvasData, getCanvasDataURL,
      openDesignPreQuestionModal, addDesignToCart,
      getPetCanvasX, getPetCanvasY, getShapeAttrObj,
      goToHomePageWithDesignModal,
      openDesignListModal, openHelpModal,
      setPopoverOpen, leaveDesign,
      undo, redo, appHistory,
      presentNamePlateTextInput,

      // event listeners
      handleStageMouseDown, handleTransformEnd,
      handleMouseEnter, handleMouseLeave, handleTrDragEnd,

      // shape actions
      moveSelectedShape, flipSelectedShape, updateShapeSize, removeShape,

      // canvas functions
      addFlowerToCanvas, boundBoxFunc, onChooseElement,
      updateSelectedShape, doShapeReorder, setCanvasSelectedShape,
      selectNextPanelShape, getOrderedCanvasShapes,
      setShapeVisible, toggleLayerVisibility,
      clearCanvasShapes,
      cloneDesign, saveCanvas, shareCanvas,
      scrollToRefElement,

      // order item functions
      getOrderItemTotal, getGroupedCanvasShapes,

      // competition functions
      openSubmitDesignModal, submitDesign,
    }
  },
}
