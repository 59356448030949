
// Vue reactivity
import { computed, defineComponent } from 'vue';

// icons
import { arrowBackOutline, close, } from 'ionicons/icons';

// components
import 'swiper/swiper-bundle.min.css';
import '@ionic/vue/css/ionic-swiper.css';

import { Swiper, SwiperSlide } from 'swiper/vue';
import SwiperCore, { Zoom, Navigation, Pagination, Autoplay } from 'swiper';
import { IonicSwiper, IonCard, IonCardHeader, IonCardSubtitle,
        IonTitle, IonIcon, IonButton, IonButtons, IonToolbar, IonHeader, IonContent,
        modalController,  } from '@ionic/vue';

SwiperCore.use([IonicSwiper, Zoom, Navigation, Pagination, Autoplay]);

// composables
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'HelpModal',
  props: [],
  components: { Swiper, SwiperSlide, IonCard, IonCardHeader, IonCardSubtitle,
                IonTitle, IonIcon, IonButton, IonButtons, IonToolbar, IonHeader, IonContent, },
  setup(props) {
    // 1. declare state variables (ref to make them reactive)
    const { t } = useI18n();

    // 2. methods or filters
    const closeModal = async () => { await modalController.dismiss() };

    // 3. return variables & methods to be used in template HTML
    return {
      // icons
      arrowBackOutline, close,

      // variables

      // methods
      t, closeModal,
    }
  },
})
